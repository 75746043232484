.Addmember {
  width: 100%;

  .headbox {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 40px;
    justify-content: space-between;
    border-bottom: 1px solid #F4F6F9;

    .headtits {
      text-align: center;
      border-bottom: 2px solid #fb9337;
      line-height: 48px;
    }

    .headtit {
      text-align: center;
      line-height: 48px;
      cursor: pointer;
    }
  }

  .conbox {
    width: 100%;
    height: 470px;
    padding-top: 15px;

    .inpbox {
      widows: 100%;
      display: flex;
      justify-content: space-between;

      .btn {
        padding: 0 16px;
        height: 36px;
        background: var(--brand-primary-base, #fb9337);
        color: #fff;
        border-color: transparent;
        border-radius: 4px;

        &:hover {
          background-color: #FFA459;
        }
      }
    }

  }
}