.AppPackage {
  width: 100%;
  height:500px;
  .head {
    width: 100%;
    display: flex;
    margin-bottom: 30px;

    .head_l {
      width: 64px;
      height: 64px;
      background-color: #B483EA;
      border-radius: 10px;
      margin-right: 15px;
    }

    .head_r {
      .tit {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .name {
        line-height: 20px;
        display: flex;
        color: #888;

        .tx {
          display: flex;
          margin-right: 15px;

          .tp {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            background-color: #000;
            margin-right: 3px;
          }
        }

        .txt {
          margin-right: 15px;
        }
      }
    }
  }

  .range {
    margin-bottom: 20px;

    .tit {
      font-weight: 700;
      margin-bottom: 10px;
    }

    .content {
      :global(.ant-select-selector) {
        border-color: #b3b2b2;
      }
    }
  }

  .add {
    width: 100%;
    display: none;
    margin-bottom: 20px;

    .addicon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-shadow: 0 4px 10px #0c1f5014;
      line-height: 18.5px;
      color: #fb9337;
      text-align: center;
      cursor: pointer;
      background-color: #fff;
      transition: all .3s;
      font-size: 20px;
      margin-right: 8px;

      &:hover {
        background-color: #fb9337;
        color: #fff;
      }
    }

    .tp {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: #000;
      margin-right: 8px;
    }
  }

  .belonging {
    height: 300px;
    position: relative;

    .tit {
      font-weight: 700;
      margin-bottom: 10px;

      span {
        color: red;
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;

      .types {
        border: 1px dashed #ddd;
        padding: 5px 10px;
        margin-right: 10px;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: #fff;
        margin-bottom: 8px;
        font-weight: 600;
        color: #494F57;

        &:hover {
          background-color: #F4F6F9;
        }
      }

      .type {
        border: 1px solid #ddd;
        padding: 5px 10px;
        margin-right: 10px;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: #fff;
        color: #444;
        width: 136px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        &:hover {
          box-shadow: 0 0 8px 2px #eee;

          .iconbox {
            opacity: 1;
          }
        }

        .iconbox {
          width: 14px;
          height: 14px;
          border-radius: 7px;
          background-color: #ACB3BD;
          font-size: 8px;
          line-height: 11.5px;
          text-align: center;
          margin: auto 0;
          cursor: pointer;
          opacity: 0;
          transition: opacity 0.3s;

          .icon {
            color: #fff;
          }
        }
      }
    }

    
  }
  .btnbox {
    position: absolute;
    bottom: 10px;
    right: 20px;

    .btn1 {
      width: 96px;
      height: 44px;
      border-radius: 5px;
      border: 1px solid #ddd;
      background-color: #fff;
      font-size: 16px;
      font-weight: 600;

      &:hover {
        background-color: #F4F6F9;
      }
    }

    .btn2 {
      width: 96px;
      height: 44px;
      border-radius: 5px;
      background-color: #FEA538;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      margin-left: 15px;

      &:hover {
        background-color: #FBA458;
      }
    }
  }
}

:global(.ant-modal-content) {
  border-radius: 13px;
  overflow: hidden;
}

.newbox {
  width: 248px;
  padding: 8px;

  :global(.ant-checkbox-wrapper) {
    width: 100%;
    height: 32px;
    line-height: 32px;
    margin-right: 0;
  }

  .addbox {
    border: 1px dashed #ddd;
    width: 100%;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #494F57;
    margin-bottom: 5px;

    &:hover {
      background-color: #F4F6F9;

    }

  }

  .box {
    width: 100%;
    height: 192px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 5px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(143, 143, 143, 0.2);
      background: #bbb9b9;
    }

    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(148, 147, 147, 0.2);
      border-radius: 10px;
      background: #ededed;
    }
  }
}

:global(.ant-popover-inner-content) {
  padding: 0px;
}

:global(.ant-popover-inner) {
  border-radius: 6px;
  overflow: hidden;
}

.newbox2 {
  width: 364px;
}