.Member {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;

  .headbox {
    width: 100%;
    height: 52px;
    padding-left: 20px;
    display: flex;

    .headtit {
      padding: 12px 14px;
      line-height: 28px;
      border-bottom: 2px solid #FEA538;
    }
  }

  .inpbox {
    width: 100%;
    padding: 8px 20px;
  }

  .conbox {
    width: 100%;
    padding: 12px 20px;

    :global(.ant-collapse-header) {
      background-color: #fff;
    }

    :global(.ant-collapse-content) {
      padding-left: 50px;
    }

    :global(.ant-checkbox-wrapper) {
      width: 100%;
      height: 32px;
      line-height: 32px;
      margin-right: 0;
    }
  }

  .bombox {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 58px;
    background-color: #F9FAFC;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    line-height: 38px;

    .bombox_l {}

    .bombox_r {
      .addbox {
        border: 1px dashed #ddd;
        width: 100%;
        height: 36px;
        text-align: center;
        line-height: 36px;
        border-radius: 3px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        color: #494F57;
        background-color: #fff;
        padding: 0 20px;
        &:hover{
          background-color: #F4F6F9;
        }
      }
    }
  }
}