.applayout :global(expand_li) {
    background-color: #0b0927;
    transition: all .2s ease;
    overflow: hidden;
    position: relative;
}

.default {
    width: 152px !important;
    min-width: 56px !important;
    max-width: 152px !important;
    flex: 0 0 152px !important;
    z-index: 11;
    background-color: rgb(11, 9, 39) !important;
}

.active {
    width: 56px !important;
    min-width: 56px !important;
    max-width: 56px !important;
    flex: 0 0 56px !important;
    z-index: 11;
}

.applayout :global(.ant-menu) {
    padding: 0 12px;
    position: relative;
}

.applayout :global(.ant-menu.ant-menu-dark) {
    background-color: #0b0927;
}

.applayout :global(.ant-menu-item) {
    padding-left: 0px !important;
    color: #fff;
    font-weight: 600;
    border-radius: 8px;
    height: 32px;
    margin-bottom: 8px;
    overflow: visible;
    display: flex;
    align-items: center;
}


.applayout :global(.ant-menu-item:hover) {
    background-color: #ffffff29;
}

.applayout :global(.ant-menu-item-selected::before) {
    position: absolute;
    top: 8px;
    left: -12px;
    height: 16px;
    border-left: 4px solid #fb9337;
    border-radius: 0 2px 2px 0;
    content: "";
}

.applayout :global(.anticon) {
    // line-height: inherit !important;
}

.applayout :global(.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon) {
    line-height: normal !important;
}

.applayout :global(.ant-menu-item-selected) {
    background-color: #ffffff29 !important;
}

.applayout :global(.ant-menu-item-icon) {
    width: 20px !important;
    height: 20px !important;
    margin: 0 6px !important;
    font-size: 20px !important;
    // line-height: 32px !important;
}

.applayout :global(.ant-menu-title-content) {
    margin-left: 0 !important;
}

.applayout :global(.ant-menu.ant-menu-inline-collapsed) {
    width: 56px;
}

:global(.ant-cascader-menu) {
    height: auto;
}


.logo {
    height: 30px;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.logoactive {
    height: 30px;
    margin-top: 10px;
    margin-left: 13px;
    margin-bottom: 10px;
}


.logoname {
    line-height: 30px;
    font-size: 19px;
    color: #fff;
    margin-left: 8px;
    font-weight: 600;
    margin-top: 10px;
    white-space: nowrap;
}

.typebox {
    display: flex;
    justify-content: space-between;

    .box_l {
        flex: 1;
        font-size: 14px;
        color: #fff;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}

.collapsed {
    height: 48px;
    width: 100%;
    padding: 0 12px;
    color: #fff;
    position: absolute;
    bottom: 0;
    z-index: 11;
    cursor: pointer;

    .expand_li {
        color: #fff;
        font-weight: 600;
        border-radius: 8px;
        padding: 2px 6px 0;
        width: 100%;
        height: 32px;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;

        &:hover {
            background-color: #ffffff29;
        }

        :global(.anticon-menu-fold) {
            width: 20px !important;
            height: 20px !important;
            font-size: 20px !important;
        }

        :global(.anticon-menu-unfold) {
            width: 20px !important;
            height: 20px !important;
            font-size: 20px !important;
        }
    }
}

.site_layout {
    position: relative;
    height: 100vh;

    .modal {
        position: absolute;
        left: 10px;
        top: 10px;

        &_warp {
            position: relative;
            z-index: 11;
            display: flex;
            flex-direction: column;
            background-color: #0b0927;
            width: 240px;
            min-height: 184px;
            max-height: 76vh;
            border-radius: 8px;
            box-shadow: 0 16px 64px #0c1f501f;
        }

        &_header {
            padding: 16px 16px 0;
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &_list {
            flex: 1;
            padding-bottom: 8px;
            overflow-y: auto;
            padding: 0 8px;

            &::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 7px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 1px;
            }

            &::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #222C50;
            }

            &::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                background: #121C3B;
            }
        }

        &_item {
            display: flex;
            align-items: center;
            height: 44px;
            border-radius: 4px;
            padding: 8px;
            outline: none;
            cursor: pointer;
            transition: background .3s, color .3s;

            img {
                width: 24px;
                height: 24px;
            }

            .item_name {
                width: 160px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0 0 0 4px;
                color: #fff;
                font-size: 14px;
            }

            &:hover {
                background-color: rgb(50, 49, 74);
            }

            .operation_icon {
                display: none;
                color: #fff;
                font-size: 20px;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                border-radius: 4px;

                &:hover {
                    background-color: rgb(66, 65, 88);
                }
            }

            &:hover .operation_icon {
                display: block;
            }
        }
    }

    .user_modal {
        position: absolute;
        bottom: 60px;
        left: 20px;

        .user_wrap {
            position: relative;
            z-index: 11;
            width: 320px;
            height: 310px;
            padding: 32px 32px 24px;
            border: 1px solid #E6EAF0;
            border-radius: 16px;
            box-shadow: 0 16px 64px #0c1f501f;
            background-repeat: no-repeat;
            background-size: 320px 310px;
            background: url('https://file.qingflow.com/assets/user/user-popover-background.png?x-oss-process=image/format,webp');

            .user_top {
                display: flex;
                align-items: center;
                margin-bottom: 32px;

                .user_name {
                    max-width: 158px;
                    margin-left: 16px;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 56px;
                    color: #121315;
                }
            }

            .user_item {
                display: flex;

                .font_color1 {
                    width: 52px;
                    font-size: 13px;
                    font-weight: 400;
                    color: #767e89;
                    word-break: keep-all;
                }

                .font_color2 {
                    font-size: 14px;
                    font-weight: 400;
                    color: #121315;
                    padding: 0 5px 4px 24px;
                }
            }

            .user_bottom {
                display: flex;
                justify-content: space-around;
                margin-top: 20px;
                font-size: 14px;

                .user_Button {
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #121315;
                    padding: 10px 8px;
                    height: 36px;
                    cursor: pointer;

                    &:hover {
                        background-color: rgb(244, 246, 249);
                    }
                }
            }

            .user_right {
                position: absolute;
                top: 50%;
                left: 100%;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 156px;
                border-radius: 0 15px 15px 0;
                box-shadow: 0 16px 64px #0c1f501f;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                color: #fff;
                cursor: pointer;
                background-color: #6468fb;

                &_font {
                    width: 13px;
                    margin: 0;
                }

                &:hover {
                    width: 32px;
                }
            }
        }
    }
}

.add_application_header {
    margin-top: 20px;
    display: flex;
    margin-bottom: 24px;

    &_avatar {
        width: 60px;
        height: 60px;
        border: 1px solid #FCDABB;
        border-radius: 12px;
        color: #fb9337;
        background: #FCEBDC;
        text-align: center;
        font-size: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }

    &_input_box {
        flex: 1;
        min-width: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        color: #121315;
        font-weight: 600;
    }
}

.add_application_content {
    font-size: 14px;
    color: #121315;
    font-weight: 600;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 66px;
}

.add_application_footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 11;
}

:global(.ant-popover-arrow) {
    display: none;
}

:global(.ant-popover-inner) {
    border-radius: 8px;
}

.popover_box {
    padding: 8px;
    box-shadow: 0 16px 64px #0c1f501f;

    .popover_li {
        min-height: 32px;
        padding: 0 8px;
        border-radius: 4px;
        line-height: 32px;
        color: #494f57;
        cursor: pointer;

        &:hover {
            background-color: rgb(247, 248, 250);
        }

        .popover_icon {
            font-size: 16px;
            color: #acb3bd;
            vertical-align: text-bottom;
            margin-right: 8px;
        }
    }
}

.defaultSidebar {
    transition: all .3s ease;
}

.activeSidebar {
    margin-left: -56px;
    transition: all .3s ease;
}

.none {
    display: none;
}

.block {
    display: inline-block;
    height: 24px;
}

.frontpage {
    position: absolute;
    width: 24px;
    height: 24px;
    z-index: 12;
    line-height: 22px;
    text-align: center;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ebecee;
    opacity: 0;
&:hover {
        opacity: 1;
        background-color: #ebecee;
    }
}
.corp_block {
    .corp_desc {
        margin-bottom: 10px;
        color: #525967
    }

    .corp_wrapper {
        display: flex;
        align-items: center;
        height: 50px;
        margin-bottom: 12px;
        overflow: auto;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .1);
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .1);

        .corp_name {
            font-weight: 700;
            font-size: 18px;
            -webkit-box-flex: 1;
            -ms-flex: auto;
            flex: auto;
            max-width: 350px;
            padding: 0 30px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .corp_op {
            // flex: 0;
            padding-right: 20px;
            color: #838892;
        }
    }

    .corp_button {
        color: #0db3a6;
        border-color: #0db3a6;
        height: 32px;
        padding: 0 20px;
        line-height: 30px;
        background: #fff;
        border: 1px solid;
        border-radius: 3px;
        text-align: center;
    }
}