@import 'antd/dist/antd.variable.min.css';
.App {
  text-align: center;
}
body {
  height: 100%;
  width: 100vw;
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}
p {
  margin: 0;
}
.col-red {
  color: #C81623 !important;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
@media screen and (max-width: 1450px) {
  /* 当小于1000的时候 */
  .font-size {
    font-size: 12px;
  }
}
.apphead > .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  height: 56px;
  line-height: 56px;
  background: #081226;
}
.min-w100 {
  min-width: 100px;
}
.min-w200 {
  min-width: 200px;
}
.min-w300 {
  min-width: 300px;
}
.min-w400 {
  min-width: 400px;
}
.ant-collapse-content-box {
  padding: 0 !important;
}
.ant-collapse {
  border: 0 !important;
}
.ant-collapse-content {
  border: 0 !important;
}
.ant-collapse-item {
  border: 0 !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
html,
body,
ul,
li,
ol,
dl,
dd,
dt,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
img {
  margin: 0;
  padding: 0;
}
fieldset,
img,
input,
button {
  border: none;
  padding: 0;
  margin: 0;
  outline-style: none;
}
ul,
ol {
  list-style: none;
}
input {
  padding-top: 0;
  padding-bottom: 0;
  font-family: "SimSun", "宋体";
}
select,
input {
  vertical-align: middle;
}
select,
input,
textarea {
  font-size: 12px;
  margin: 0;
}
textarea {
  resize: none;
}
img {
  border: 0;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
a {
  color: #666;
  text-decoration: none;
}
a:hover {
  color: #C81623;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-decoration: none;
  font-weight: normal;
}
s,
i,
em {
  font-style: normal;
  text-decoration: none;
}
.col-red {
  color: #C81623 !important;
}
#loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-size: 20px;
}
